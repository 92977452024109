<template>
  <div class="app-header-nav">
    <div class="h_wrap left">
      <div class="h_item home">
        <a @click="goindex">{{ langvalue.top.twen }}</a>
      </div>
      <div class="h_item">
        <a @click="goPage('/about')">{{ langvalue.top.one }}</a>
      </div>
      <div class="h_item home" @click="overmenu(3)">
        <a>{{ langvalue.top.three }}</a>
        <img
          :class="[menuflag && iNum == 3 ? 'tras' : '', 'icon']"
          src="../assets/images/down.png"
        />
        <div
          class="layer"
          v-if="menuflag && iNum == 3"
          @mouseover="overmenu(3)"
          @mouseleave="lemenu"
        >
          <ul>
            <li>
              <p @click="goPage('/trustcenter')">{{ langvalue.Bus_introduction.one }}</p>
            </li>
            <li>
              <p @click="goPage('/csList')">{{ langvalue.Bus_introduction.two }}</p>
            </li>
           
            <li>
              <p @click="goPage('/center')">
                {{ langvalue.Bus_introduction.three_1 }}
              </p>
            </li>
            <!-- <li>
              <p @click="goPage('/institute')">
                {{ langvalue.Bus_introduction.three_2 }}
              </p>
            </li> -->
            <li>
              <p @click="goPage('/slinfo')">
                {{ langvalue.Bus_introduction.three }}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="h_wrap center">
      <h1 class="logo" @click="goindex"></h1>
      <h1 class="title">WKT</h1>
    </div>
    <div class="h_wrap right">
      <div class="h_item home" @click="overmenu(4)">
        <a>{{ langvalue.top.twe }}</a>
        <img
          :class="[menuflag && iNum == 4 ? 'tras' : '', 'icon']"
          src="../assets/images/down.png"
        />
        <div
          class="layer"
          v-if="menuflag && iNum == 4"
          @mouseover="overmenu(4)"
          @mouseleave="lemenu"
        >
          <ul>
            <li>
              <p @click="goCompo">{{ langvalue.news.one }}</p>
            </li>
            <li>
              <p @click="goHy">{{ langvalue.news.two }}</p>
            </li>
            <li>
              <p @click="goKnow">
                {{ langvalue.news.three }}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="h_item" @click="goContact">
        <a>{{ langvalue.top.ten }}</a>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../assets/js/language'
import { useRouter } from 'vue-router'
export default {
  name: 'AppHeaderNav',
  setup() {
    const store = useStore()

    // 如果小于14寸的屏幕，就缩小缩放比例为0.8
    const isSmallScreen = computed(() => {
      return store.state.app.isSmallScreen
    })

    const scale = computed(() => {
      return isSmallScreen.value ? 0.8 : 1
    })

    const router = useRouter()
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype))
    const list = computed(() => {
      return store.state.category.list
    })
    // console.log('langvalue.top', langvalue.value.top);
    /* 跳转首页 */
    const goindex = () => {
      router.push('/')
    }
    const goPage = rou => {
      router.push(rou)
    }
    /* 跳转联系我们 */
    const goContact = () => {
      router.push('/contact')
    }

    // 跳转的时候不会关闭二级类目，通过数据来控制
    // 1. vuex每个分类加上open数据
    // 2. vuex提供显示和隐藏函数，修改open数据
    // 3. 组件中使用vuex中的函数，使用时间来绑定，提供一个类名显示隐藏的类名

    const show = item => {
      store.commit('category/show', item.id)
    }
    const hide = item => {
      store.commit('category/hide', item.id)
    }
    const gomao = value => {
      window.localStorage.setItem('mao', value)
      // console.log(router.currentRoute.value.fullPath);
      if (router.currentRoute.value.fullPath != '/') {
        router.push('/')
        // setTimeout(() => {
        //   document.querySelector(value).scrollIntoView({
        //     behavior: 'smooth',
        //     block: 'start',
        //     offset: 50
        //   })
        // }, 200)
      } else {
        // document.querySelector(value).scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'start',
        //   offset: 50
        // })
      }
    }
    /* 公司新闻 */
    const goCompo = () => {
      router.push('/connews')
      // console.log('跳转公司新闻')
    }

    /* 知识科普 */
    const goKnow = () => {
      router.push('/knowledge')
      // console.log('跳转公司新闻')
    }
    /* 行业新闻 */
    const goHy = () => {
      router.push('/hynew')
    }
    const menuflag = ref(false)
    let iNum = ref(0)
    const overmenu = i => {
      // console.log('i', i)
      menuflag.value = true
      iNum.value = i
    }
    const lemenu = () => {
      menuflag.value = false
    }

    watch(
      () => router.currentRoute.value.fullPath,
      newVal => {
        setTimeout(() => {
          if (newVal == '/') {
            gomao(window.localStorage.getItem('mao'))
          }
        }, 500)
      },
      { immediate: true }
    )
    watch(
      () => store.state.home.type,
      newVal => {
        langvalue.value = returnLanguage(newVal)
      },
      { immediate: true }
    )
    return {
      list,
      show,
      hide,
      langvalue,
      gomao,
      overmenu,
      menuflag,
      iNum,
      lemenu,
      goindex,
      goCompo,
      goKnow,
      goHy,
      goContact,
      goPage
    }
  }
}
</script>
<style scoped lang="less">
.home {
  cursor: pointer;
  position: relative;
}

.app-header-nav {
  width: 1200px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 999;
  font-family: PingFangSC-Regular, PingFang SC;
  .left {
    width: 38%;
    color: #222121;
    justify-content: space-between;
  }
  .right {
    width: 28%;
    // justify-content: space-between;
  }
  .center {
    margin: 0 auto;
  }
  .h_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    font-weight: 400;
    .h_item {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      .icon {
        width: 20px;
        height: 20px;
      }
      .tras {
        transition: all 300ms;
        transform: rotate(180deg);
      }
    }
    .logo {
      width: 73px;
      height: 93px;
      background: url(../assets/images/wk.png) no-repeat;
      cursor: pointer;
    }
    .title {
      font-size: 40px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222121;
      padding-left: 17px;
      cursor: pointer;
    }
  }
  // > li {
  //   margin-right: 10px;
  //   // width: 38px;
  //   text-align: center;
  //   > a {
  //     font-size: 14px;
  //     line-height: 32px;
  //     height: 32px;
  //     display: inline-block;
  //   }
  //   &:hover,
  //   &.active {
  //     > a {
  //       color: @xtxColor;
  //       border-bottom: 1px solid @xtxColor;
  //     }
  //     // 显示二级类目
  //     // > .layer {
  //     //   height: 132px;
  //     //   opacity: 1;
  //     // }
  //   }
  // }
}
// 二级类名弹层
.layer {
  &.open {
    // height: 132px;
    opacity: 0;
  }
  width: 160px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  left: -41px;
  top: 44px;
  overflow: hidden;
  box-shadow: 0px 0px 4px #eaf3ff;
  transition: all 0.2s 0.1s;
  padding: 8px;
  ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;

    li {
      text-align: center;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      height: 44px;
      line-height: 44px;
      background: #f9fcff;
      border-radius: 4px;
      &:hover {
        p {
          color: @xtxColor;
        }
      }
    }
    li + li {
      margin-top: 8px;
    }
  }
}
</style>
