<template>
  <footer class="app-footer">
    <div class="extra">
      <div class="detail">
        <div class="title">
          {{ langvalue.top.ten }}
        </div>
        <div class="stitle">{{ langvalue.section10.two }}</div>
        <div class="stitle">{{ langvalue.section10.three }}</div>
        <div class="stitle">{{ langvalue.section10.one }}</div>
      </div>
    </div>
  </footer>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import { returnLanguage } from "@/assets/js/language";
export default {
  name: "AppHeaderNav",
  setup() {
    const store = useStore();
    // 拿到vuex中的分类列表
    const langtype = store.state.home.type;
    const type = ref(langtype);
    // eslint-disable-next-line no-undef
    const langvalue = ref(returnLanguage(langtype));

    const playvideo = () => {
      document.getElementById("video").play();
    };
    const jiankongvideo = () => {
      document.getElementById("video").play();
    };
    watch(
      () => store.state.home.type,
      (newVal) => {
        langvalue.value = returnLanguage(newVal);
        type.value = newVal;
      },
      { immediate: true }
    );
    return { langvalue, playvideo, jiankongvideo, type };
  },
};
</script>

<style scoped lang='less'>
.app-footer {
  overflow: hidden;
  background-color: #fafcff;
  .contact {
    background: #fff;
    .container {
      padding: 60px 0 40px 25px;
      display: flex;
    }
    dl {
      height: 190px;
      text-align: center;
      padding: 0 72px;
      border-right: 1px solid #f2f2f2;
      color: #999;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
    dt {
      line-height: 1;
      font-size: 18px;
    }
    dd {
      margin: 36px 12px 0 0;
      float: left;
      width: 92px;
      height: 92px;
      padding-top: 10px;
      border: 1px solid #ededed;
      .iconfont {
        font-size: 36px;
        display: block;
        color: #666;
      }
      &:hover {
        .iconfont {
          color: @xtxColor;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .qrcode {
      width: 92px;
      height: 92px;
      padding: 7px;
      border: 1px solid #ededed;
    }
    .download {
      padding-top: 5px;
      font-size: 14px;
      width: auto;
      height: auto;
      border: none;
      span {
        display: block;
      }
      a {
        display: block;
        line-height: 1;
        padding: 10px 25px;
        margin-top: 5px;
        color: #fff;
        border-radius: 2px;
        background-color: @xtxColor;
      }
    }
    .hotline {
      padding-top: 20px;
      font-size: 22px;
      color: #666;
      width: auto;
      height: auto;
      border: none;
      small {
        display: block;
        font-size: 15px;
        color: #999;
      }
    }
  }
  .extra {
    background-color: #333;
    padding: 30px;
    color: white;
    text-align: center;
    .detail {
      // width: 1240px;
      // margin: 0 auto;
      .title {
        font-size: 20px;
        font-weight: bold;
        margin: 20px 0;
      }
      .stitle {
        font-size: 16px;
        color: #ffffff61;
      }
    }
  }
  .slogan {
    height: 178px;
    line-height: 58px;
    padding: 60px 100px;
    border-bottom: 1px solid #434343;
    display: flex;
    justify-content: space-between;
    a {
      height: 58px;
      line-height: 58px;
      color: #fff;
      font-size: 28px;
      i {
        font-size: 50px;
        vertical-align: middle;
        margin-right: 10px;
        font-weight: 100;
      }
      span {
        vertical-align: middle;
        text-shadow: 0 0 1px #333;
      }
    }
  }
  .copyright {
    height: 170px;
    padding-top: 40px;
    text-align: center;
    color: #999;
    font-size: 15px;
    p {
      line-height: 1;
      margin-bottom: 20px;
    }
    a {
      color: #999;
      line-height: 1;
      padding: 0 10px;
      border-right: 1px solid #999;
      &:last-child {
        border-right: none;
      }
    }
  }
}
</style>
